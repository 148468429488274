@import "../../../shared/styles/variable";

.customer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.customer-header-input-view {
  max-width: 120px;

  @media screen and (min-width: @screen-sm) {
    max-width: 150px;
  }
}

.customer-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  gap: 12px;

  [dir="rtl"] & {
    padding-left: 0;
    padding-right: 10px;
  }
}

.customer-header-pagination {
  display: none;
  padding-left: 12px;
  padding-right: 2px;

  @media screen and (min-width: @screen-md) {
    display: block;
  }

  [dir="rtl"] & {
    padding-left: 2px;
    padding-right: 12px;
  }
}

.customer-footer-pagination {
  display: block;
  padding: 10px;

  @media screen and (min-width: @screen-md) {
    display: none;
  }
}

.customer-table {
  & .ant-table table {
    table-layout: auto !important;
  }

  & .ant-table-thead > tr > th {
    font-size: 13px;
    padding: 8px;
    font-weight: @font-weight-bold;
    background-color: transparent;

    &:first-child {
      padding-left: 20px;

      [dir="rtl"] & {
        padding-left: 8px;
        padding-right: 20px;
      }
    }

    &:last-child {
      padding-right: 12px;
      padding-left: 12px;
    }

    &.customer-table-actions {
      text-align: center;
      background-color: @component-background !important;
    }
  }

  & .ant-table-tbody > tr > td {
    font-size: 13px;
    padding: 12px 8px;

    &:first-child {
      padding-left: 20px;

      [dir="rtl"] & {
        padding-left: 8px;
        padding-right: 20px;
      }
    }

    &:last-child {
      padding-right: 12px;
      padding-left: 12px;
    }

    &.customer-table-actions {
      text-align: center;
      background-color: @component-background !important;
    }
  }

  & .badge {
    padding: 3px 8px;
    color: @white;
    background-color: @green-6;
    width: 45px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    height: 18px;
    border-radius: 10px;
    font-size: @font-size-sm;

    [dir="rtl"] & {
      margin-right: 0;
      margin-left: 8px;
    }
  }
}

.order-id {
  text-decoration: underline;
  color: @primary-color;
}

.edit-customer-form {
  position: relative;

  & .ant-form-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.show-customer-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.steps-action {
  margin-top: 24px;
}

@primary-color: #0A8FDC;